<template>
    <div id="calendario" class="pla">
         <div id="headerHome" class="col-sm-12 col-md-12  color-bg-1">
                <HeaderHome :name="user.fullname" /> 
        </div>
        
        <PlayerCont />
         <div id="footerSection">
             <Footer />
         </div>
    </div>
</template>
<script>
import Footer from '../components/footer';
import HeaderHome from '../components/HeaderHome';
import PlayerCont from '../components/PlayerCont';

export default {
    components: {
        HeaderHome,
        Footer,
        PlayerCont,
    },
    computed: {
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
    },
    
}
</script>